import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';
import App from './Admin.vue';
import router from './router/admin';
import vuetify from './plugins/vuetify';
import NavbarAdminComponent from './components/NavbarAdminComponent.vue';

Vue.component('navbar-component', NavbarAdminComponent);
Vue.component('apex-chart', VueApexCharts);

Vue.config.productionTip = false;

function getToken() {
  return window.localStorage.getItem('token.admin');
}

function setToken(token) {
  window.localStorage.setItem('token.admin', token);
}

function removeToken() {
  window.localStorage.removeItem('token.admin');
}

const storage = new Map();

router.beforeEach(async (to, from, next) => {
  if (to?.meta?.auth) {
  // auth
    console.log(storage.get('t'));
    next();
  } else {
    next();
  }
});

new Vue({
  router,
  vuetify,
  async created() {
  },
  async mounted() {
  },
  data() {
    return {
      storage,
      drawer: !this.$vuetify.breakpoint.mdAndDown,
      pageTitle: '',
      token: this.getToken(),
      user: null,
    };
  },
  methods: {
    getToken,
    setToken,
    removeToken,
    async getAuth() {
      if (this.token) {
        const auth = await this.auth();
        if (auth) {
          this.user = auth;
        }
      }
    },
    async auth() {
      const auth = await this.$root.getJSON({
        url: `${window.env.API}/api/admin/auth`,
      });
      return auth;
    },
    goLogin() {
      this.$router.push('/admin/login');
    },
    postJSON(request) {
      return fetch(request.url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`,
        },
        body: JSON.stringify(request.body),
      })
        .then((res) => {
          if (!res.ok) {
            switch (res.status) {
              case 401:
                this.exit();
                break;
              default:
                throw new Error(res.statusMessage);
            }
          }
          return res.json();
        });
    },
    getJSON(request) {
      return fetch(request.url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`,
        },
      })
        .then((res) => {
          if (!res.ok) {
            switch (res.status) {
              case 401:
                this.exit();
                break;
              default:
                throw new Error(res.statusMessage);
            }
          }
          return res.json();
        });
    },
    deleteJSON(request) {
      return fetch(request.url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`,
        },
      })
        .then((res) => {
          if (!res.ok) {
            switch (res.status) {
              case 401:
                this.exit();
                break;
              default:
                throw new Error(res.statusMessage);
            }
          }
          return res.json();
        });
    },
    getTEXT(request) {
      return fetch(request.url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${this.token}`,
        },
      })
        .then((res) => {
          if (!res.ok) {
            switch (res.status) {
              case 401:
                this.exit();
                break;
              default:
                throw new Error(res.statusMessage);
            }
          }
          return res.text();
        });
    },
    exit() {
      this.removeToken();
      this.token = null;
      this.user = null;
      this.goLogin();
    },
    logout() {
      this.exit();
      this.goLogin();
    },
  },
  render: (h) => h(App),
}).$mount('#app');
