<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'AdminApp',
  async mounted() {
  },
  components: {},
  data: () => ({}),
};
</script>
