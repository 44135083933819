import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/admin/sales',
  },
  {
    path: '/admin',
    redirect: '/admin/sales',
  },
  {
    path: '/admin/sales',
    name: 'admin.prices',
    component: () => import(/* webpackChunkName: "admin.prices" */ '../views/admin/PricesLayout.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/admin/products',
    name: 'admin.sources',
    component: () => import(/* webpackChunkName: "admin.sources" */ '../views/admin/SourcesLayout.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/admin/groups',
    name: 'admin.products',
    component: () => import(/* webpackChunkName: "admin.sources" */ '../views/admin/ProductsLayout.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/admin/groups/:id',
    name: 'admin.product',
    component: () => import(/* webpackChunkName: "admin.prices" */ '../views/admin/ProductLayout.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/admin/login',
    name: 'admin.login',
    component: () => import(/* webpackChunkName: "admin.login" */ '../views/admin/LoginView.vue'),
  },
  {
    path: '/admin/scrappers',
    name: 'admin.scrappers',
    component: () => import(/* webpackChunkName: "admin.scrappers" */ '../views/admin/ScrappersLayout.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/admin/shops',
    name: 'admin.shops',
    component: () => import(/* webpackChunkName: "admin.shops" */ '../views/admin/ShopsLayout.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/admin/categories',
    name: 'admin.categories',
    component: () => import(/* webpackChunkName: "admin.categories" */ '../views/admin/CategoriesLayout.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/admin/poster',
    name: 'admin.poster',
    component: () => import(/* webpackChunkName: "admin.poster.jobs" */ '../views/admin/PosterJobsLayout.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/admin/rocket',
    name: 'admin.rocket',
    component: () => import(/* webpackChunkName: "admin.poster.jobs" */ '../views/admin/RocketLayout.vue'),
    meta: {
      auth: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
